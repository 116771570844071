
import './App.css';
import Home from './Home'
import Product from "./pages/product"
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Waitlist from './pages/waitlist';
import Backstory from './pages/backstory';
import Investor from './pages/investor';
import Privacy from './pages/privacy';
import Thepod from './pages/thepod';
import Joinus from './pages/joinus';
import Research from './pages/research';


function App() {

  return (
   <div 
   
    >
    <Router>
      <Routes>
        <Route path="/"  element={<Home />}/>
        <Route path="/research" element={<Research/>}/>
        <Route path="/privacy"  element={<Privacy/>}/>
        <Route path="/products"  element={<Product/>}/>
        <Route path="/join-us"  element={<Joinus/>}/>
        <Route path="/the-pod"  element={<Thepod/>}/>
        <Route path="/waitlist"  element={<Waitlist/>}/>
        <Route path="/about-the-pod"  element={<Backstory/>}/>
        <Route path="/investor-interest"  element={<Investor/>}/>
      </Routes>
    </Router>
   </div>
  );
}

export default App;