import React, { useEffect, useState } from 'react';
import db from "../firebase"
import firebase from "firebase/compat/app";

import Marquee from "react-fast-marquee"
import { CheckIcon } from '@heroicons/react/20/solid'
import StaggerText from "react-stagger-text"
import BodyOne from '../components/bodyone';




function Research() {

  const [showTextbox, setShowTextbox] = useState(false);
  const [finished, setFinished] = useState(false);
  const [typeEmail, setTypeEmail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);

  const currentEmail = (event) =>{
    setTypeEmail(event.target.value);
  }

  const submitEmail = async (e) => {
    e.preventDefault();

    db.collection("emails").add({
      email: typeEmail,
    }).then(() => {
      setTypeEmail('');
      setFinished(true);
      console.log("IT WORKS")
    }).catch((error) => {
      console.error("Error adding document: ", error);
      console.log("IT WORKS")
    });
  }

  const repeatedText = Array(60).fill("et and stylish necklace that is more than just a fashion statement. A discre");
  const repeatedTextTwo = Array(60).fill(" Unconscious cataloging of nutritional intake.").join(' ');



  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
        <div className="bg-gray-100 text-gray-500">
          <a className="p-2" href="/"><span aria-hidden="true">&larr;</span> Home </a>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
          
        </div>
        
        <br/><br/>
        <div className="container mx-auto pt-16 pb-8 px-16">

              <div class=" justify-center ...">
                <p className="text-xl leading-8 text-gray-900 mb-10">
                <p className="text-gray-500">
                <StaggerText>
                Our proposition for unconscious cataloging of nutritional intake
                  </StaggerText>
                </p>
                  <br/><br/>
                  <StaggerText>

                  Our research focuses on advancing AI-driven food detection in everyday conversations. diAI's sophisticated models recognize and estimate carbohydrate content to bridge the gap between conversational understanding and real-time dietary insights.

                  </StaggerText>
                  <br/><br/>
                  <StaggerText>

                  We’ve developed a cutting-edge speech recognition system integrated with natural language processing, trained on an extensive dataset of food items, nutritional information, and insulin dosing guidelines. This enables our AI to interpret spoken food descriptions and accurately calculate carbohydrate content in real time.
                  </StaggerText>
                  <br/><br/>
                  <StaggerText>
                  Our software utilizes a custom-trained large language model, built on over 6,000 data points covering a wide range of foods and their carbohydrate values. This advanced AI algorithm seamlessly predicts carb content from a curated database, providing reliable and efficient nutritional insights.”
                  </StaggerText>
                  <br/><br/>
                  <p className="text-gray-500">
                    <i>
                    <StaggerText>
                    *Please note that this research is still ongoing.
                    </StaggerText>
                   
                    </i>
                </p>
                    
                </p>



              </div>
        </div>

       
    </div>
   
  );
}

export default Research;
