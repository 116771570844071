import React, { useEffect } from 'react';
import StaggerText from "react-stagger-text";
import image from "../images/r_image.webp"
import AOS from "aos";
import "aos/dist/aos.css";
import './Resizing.css';

function ResearchSm() {

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 max-w-7xl" style={{margin: 'auto'}}>


      <p className="text-gray-100">research</p>
      <div className="border-t border-gray-100 mb-5"></div>
      {/* Main content section */}
      <div style={{marginTop: '100px'}} className="flex flex-col md:flex-row items-center justify-center mt-5">
        <div className="padding-left w-full mb-10 md:mb-0 flex flex-col justify-center md:text-left">
          <h1 className="text-2xl font-semibold mb-5">
          Our proposition for unconscious cataloging of nutritional intake
          </h1>
          <p className="text-lg leading-8 text-gray-900 mb-10">
            <StaggerText>
            Our research focuses on advancing AI-driven food detection in everyday conversations. diAI's sophisticated models recognize and estimate carbohydrate content to bridge the gap between conversational understanding and real-time dietary insights.
            </StaggerText>
          </p>
          <div data-aos="fade-right" className="flex flex-col sm:flex-row gap-4">
            <a style={{textAlign: 'center'}} href="/research" className="block mb-2 text-base sm:text-lg font-semibold leading-7 text-black">
              Read More <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>

        {/* Image section */}
        <div className="md:w-3/4 mb-12 w-full flex justify-center">
          <div className="rounded-2xl">
            <img src={image} className="w-80 h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg" alt="diAI wearable device" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResearchSm;