import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';
import StaggerText from "react-stagger-text";
import TextTransition, { presets } from 'react-text-transition';
import { useState, useEffect } from 'react';
import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css"; 
import './Resizing.css';
import img from '../images/main.webp';

const links = [
  { name: 'Join Waitlist', href: '/waitlist' },
  { name: 'Submit Investor Interest', href: '/investor-interest' },
  { name: 'Back Story', href: '/about-the-pod' },
];

const TEXTS = ['Catalogs', 'Learns', 'Chats', 'Stays'];

export default function ResearchHeader() {
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % TEXTS.length);
    }, 3000); // every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
      <div className="block-one-one min-h-screen bg-gradient-to-b from-black-100 to-white p-8">
        <div className="max-w-6xl mx-auto">
          <div className="text-sm space-x-10 mb-8 font-medium">
            <span className="text-gray-500">diAI</span> <a href="/" className="underline">Research</a> <a href="/products" className="underline">Products</a>
          </div>
          
          <div className="flex mt-32 items-center justify-center space-x-20">
            <div className="max-w-lg">
            <h1 className="text-4xl font-semibold mb-6">
                Pioneering research on the path to implicit nutritional intake
            </h1>
            <p className="text-lg mb-8">
              <StaggerText>
                We believe our research will lead to breakthroughs in understanding food within conversational contexts, enabling AI to estimate nutritional content in real-time. Empowering individuals to make informed dietary choices is our mission.
              </StaggerText>
              <br />
              <StaggerText>
                We build our models using deep learning, which processes large datasets to train AI systems that can detect and quantify carbohydrates in food based on dialogue.
              </StaggerText>
            </p>
              <div data-aos="fade-right" className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
                {links.map((link, index) => (
                  <div key={index}>
                    <a href={link.href} className="block mb-2">
                      {link.name} <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-80">
              <div style={{ transform: 'rotate(4deg)' }} className="pt-4">
                <div className="blur-xl rounded-2xl">
                  <img src={img} style={{ maxWidth: '280px', width: '100%', height: 'auto' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="block-one-two min-h-screen bg-gradient-to-b from-black-100 to-white p-8">
        <div className="max-w-6xl mx-auto">
          <div className="text-sm mb-8">
            We're hiring. <a href="/join-us" className="underline">Join our team</a>
          </div>
          
          <div className="justify-center">
            <div className="max-w-lg">
              <h1 className="text-5xl font-bold mb-6">
                Meet <span><a href="/the-pod">diAI</a></span>, the AI that
                <span>
                  <TextTransition springConfig={presets.wobbly}>{TEXTS[index]}</TextTransition>
                </span>
                <span>with you</span>
              </h1>
              <p className="text-xl mb-8">
                <StaggerText>
                  diAI is a personal AI designed to truly understand you, alleviating the stress of your lifestyle and forming a comprehensive picture of your health. Whether you’re interested in tracking food intake, exercise, or reflecting on life’s challenges, the diAI Pod is there to offer personalized guidance.
                </StaggerText>
              </p>
              <div data-aos="fade-right" className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
                {links.map((link, index) => (
                  <div key={index}>
                    <a href={link.href} className="block mb-2">
                      {link.name} <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
// import StaggerText from "react-stagger-text"

// import TextTransition, { presets } from 'react-text-transition';
// import { useState, useEffect } from 'react';
// import React from 'react';

// import AOS from "aos";
// import "aos/dist/aos.css"; 

// import './Resizing.css'

// import img from '../images/main.webp'
// // import img from '../images/PhoneHeader.webp'

// import './Resizing.css'

// const links = [
//   { name: 'Join Waitlist', href: '/waitlist' },
//   { name: 'Submit Investor Interest', href: '/investor-interest' },
//   { name: 'Back Story', href: '/about-the-pod' },
// ]

// const TEXTS = ['Catalogs', 'Learns', 'Chats', 'Stays'];


// const features = [
//   {
//     name: 'Push to deploy.',
//     description:
//       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
//     icon: CloudArrowUpIcon,
//   },
//   {
//     name: 'SSL certificates.',
//     description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
//     icon: LockClosedIcon,
//   },
//   {
//     name: 'Database backups.',
//     description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: ServerIcon,
//   },
// ]

// export default function ResearchHeader() {


//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(
//       () => setIndex((index) => index + 1),
//       3000, // every 3 seconds
//     );
//     return () => clearTimeout(intervalId);
//   }, []);

//   useEffect(() => {
//     AOS.init({ duration: 2000 }); //might need to be AOS
//   }, []);


//   return (
//     <div>
//       <div className="block-one-one min-h-screen bg-gradient-to-b from-black-100 to-white p-8">
//         <div className="max-w-6xl mx-auto">
//           <div className="text-sm space-x-10 mb-8 font-medium">
//             <span style={{color: 'grey'}}>diAI</span> <a href="/" className="underline">Research</a> <a href="/products" className="underline">Products</a>
//           </div>
          
//           <div className="flex mt-32 items-center justify-center space-x-20"> {/* Use space-x-4 for spacing between items */}
//             <div className="max-w-lg">
//               <h1 className="text-4xl font-semibold mb-6 ">
//               Pioneering research on the path to AGI
//               </h1>
//               <p className="text-lg mb-8">
                
//                 <StaggerText>
//                 We believe our research will eventually lead to artificial general intelligence, a system that can solve human-level problems. Building safe and beneficial AGI is our mission.
//                 </StaggerText>

//                 <br/>

//                 <StaggerText>
//                 We build our generative models using a technology called deep learning, which leverages large amounts of data to train an AI system to perform a task.
//                 </StaggerText>


//               </p>
//               <div data-aos="fade-right" className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
//                 {links.map((link, index) => (
//                   <div key={index}>
//                     <a href={link.href} className="block mb-2" >
//                       {link.name} <span aria-hidden="true">&rarr;</span>
//                     </a>
//                   </div>
//                 ))}
//             </div>
//               {/* <button className="bg-brown-600 text-black px-6 py-3 rounded-full text-lg font-semibold">
//                 Download YourAI for iOS
//               </button> */}
//             </div>

//             <div className="w-80">
//               <div style={{transform: 'rotate(4deg)'}} className="pt-4">
//                 <div className="blur-xl rounded-2xl">
//                   <img src={img} style={{ maxWidth: '280px', width: '100%', height: 'auto' }} />
//                 </div>
//               </div>
//             </div>
//           </div>

          

//         </div>

//       </div>

//       <div className="block-one-two min-h-screen bg-gradient-to-b from-black-100 to-white p-8">
//         <div className="max-w-6xl mx-auto">
//           <div className="text-sm mb-8">
//             We're hiring. <a href="/join-us" className="underline">Join our team</a>
//           </div>
          
//           <div className="justify-center ..."> {/* Use space-x-4 for spacing between items */}
//             <div className="max-w-lg">
//               <h1 className="text-5xl font-bold mb-6">
//                 Meet <span><a href="/the-pod">diAI</a></span>, the AI that
//                 <span>
//                   <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
//                 </span>
//                 <span>with you</span>
//               </h1>
//               <p className="text-xl mb-8">
                
//                 <StaggerText>
//                 diAI is a personal AI designed to truly understand you, alleviating the stress of your lifestyle and forming a comprehensive picture of your health. Whether you’re interested in tracking food intake, exercise, or reflecting on life’s challenges, the diAI Pod is there to offer personalized guidance. 

//               </StaggerText>

//               </p>
//               <div data-aos="fade-right" className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
//                 {links.map((link, index) => (
//                   <div key={index}>
//                     <a href={link.href} className="block mb-2" >
//                       {link.name} <span aria-hidden="true">&rarr;</span>
//                     </a>
//                   </div>
//                 ))}
//             </div>
//               {/* <button className="bg-brown-600 text-black px-6 py-3 rounded-full text-lg font-semibold">
//                 Download YourAI for iOS
//               </button> */}
//             </div>
//           </div>

          

//         </div>

      

        
//       </div>
//     </div>

//   )
// }
